import { Component, Input, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';
declare const powerbi: any;
type Practice = {
  isSelected: boolean;
  name: string;
  practiceId: number;
  npi: string;
  programType: string;
};

@Component({
  selector: 'app-PBI',
  templateUrl: './PBI.component.html',
  styleUrls: ['./PBI.component.scss']
})
export class PBIComponent implements OnInit {
  @Input() config: any;
  private report: any;
  selectedPractice: any;
  practiceNames: string[] = [];

  constructor(private readonly loggingService: LoggingService) { }

  ngOnInit(): void {
    this.loggingService.eventService.selectedPracticeSubscription().subscribe((practice) => {
      this.selectedPractice = practice;
      this.practiceNames = this.selectedPractice
                  .filter((practice: Practice) => practice.isSelected)
                  .map((practice: Practice) => practice.name)
     })
    this.embedReport();
  }
  async applyFilters(): Promise<void> {
    try {
      const basicFilter = {
        $schema: "https://powerbi.com/product/schema#basic",
        target: {
          table: "reporting Daily_CKCC_Census",
          column: "KCC POD"
        },
        operator: "In",
        values: this.practiceNames
      };
      // Set the basic filter directly
      await this.report.setFilters([basicFilter]);
      
      // If you have additional filters from config, you can combine them
      if (this.config.filters && this.config.filters.length > 0) {
        const allFilters = [basicFilter, ...this.config.filters];
        await this.report.setFilters(allFilters);
      }

      // Refresh report to reflect new filters
      await this.report.refresh();
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  }

  embedReport(): void {
    const embedContainer = document.getElementById('embedContainer');

    const config = {
      type: 'report',
      id: this.config.id,
      embedUrl: this.config.embedUrl,
      accessToken: this.config.embedToken.token,
      tokenType: 1,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          }
        },
      }
    };
    this.report = powerbi.embed(embedContainer, config);

    this.report.off("loaded");

    this.report.on("loaded", () => {
      this.applyFilters();
    });
    

    this.report.off("error");

    this.report.on("error", (error: any) => {
      console.error(error);
    });
  }
}
